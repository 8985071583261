<template>
	<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height" x="0" y="0" viewBox="0 0 96 96" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
        <g>
            <path d="M49.822 44.294 38.48 35.505c-.812-.604-1.737-.923-2.676-.923-2.398 0-4.275 2.045-4.275 4.656V56.76c0 2.611 1.877 4.656 4.275 4.656.939 0 1.864-.319 2.694-.936l11.525-8.747a4.362 4.362 0 0 0 1.766-3.534 4.918 4.918 0 0 0-1.967-3.905zM36.236 56.288l.021-16.551 10.681 8.278.072.099z" :fill="iconColor" opacity="1" data-original="#000000">
            </path>
            
            <path d="M85.256 27.891a5.26 5.26 0 0 0-5.381.203l-9.507 6.17v-3.089c0-6.497-5.267-11.765-11.765-11.765H19.765C13.267 19.41 8 24.678 8 31.175v33.65c0 6.497 5.267 11.765 11.765 11.765h38.838c6.498 0 11.765-5.268 11.765-11.765V61.72l9.503 6.182c.865.564 1.86.849 2.86.849a5.3 5.3 0 0 0 2.526-.642A5.208 5.208 0 0 0 88 63.534V32.467a5.208 5.208 0 0 0-2.744-4.576zM65.662 64.825a7.067 7.067 0 0 1-7.059 7.059H19.765a7.067 7.067 0 0 1-7.059-7.059v-33.65a7.067 7.067 0 0 1 7.059-7.059h38.838a7.067 7.067 0 0 1 7.059 7.059zm17.632-1.291c0 .19-.094.337-.279.437a.543.543 0 0 1-.576-.011l-12.071-7.854v-16.23l12.074-7.834a.543.543 0 0 1 .573-.01c.185.1.279.247.279.438z" :fill="iconColor" opacity="1" data-original="#000000">
            </path>
        </g>
    </svg>
</template>

<script>
export default {
	name: "LiveStreamIcon",
	props: {
		height: {
			type: Number,
			default: 40
		},
		width: {
			type: Number,
			default: 40
		},
        iconColor: {
            type: String,
            default: '#ffffff'
        }
  	}
}
</script>
