<template>
    <div>
<!--        <default-camera-app v-if="step === 0 && isMobile" key="camera-app"/>-->
        <record-screen
            v-if="step === 0"
            key="screen"
            :is-front-camera="isFrontCamera"
            @change-camera="isFrontCamera = $event"
        />
        <view-result v-if="step === 1" key="result" @close="handleCloseResult()"/>
        <before-posting-screen v-if="step === 2" key="posting"/>
        <v-bottom-navigation
            dark
            fixed
            class="bottom-bar justify-center px-2"
            v-model="value"
            :height="bottomBarHeight"
            :input-value="active">
            <template v-if="active">
                <div class="bottom-bar--buttons">
                    <v-btn :min-width="buttonSize" :to="{name: 'auth.home'}" active-class="orange-color" :ripple="false">
                        <app-icon icon="home" :color="getIconColor()" :active="isActive('auth.home')"></app-icon>
                    </v-btn>

                    <v-btn :min-width="buttonSize" :to="{name: 'notification'}" :ripple="false">
                        <v-badge
                            v-if="unreadNotifications > 0"
                            :content="unreadNotificationsStr"
                            :offset-y="10"
                            color="#FE4811"
                        >
                            <app-icon icon="bell" :color="getIconColor('notification')" :active="isActive('notification')"/>
                        </v-badge>
                        <app-icon v-else icon="bell" :color="getIconColor()" :active="isActive('notification')"/>
                    </v-btn>
                    <input type="file" style="display: none" id="camera-input" accept="image/*" capture="camera"/>
                    <v-btn
                        :height="buttonSize"
                        :min-width="buttonSize"
                        :max-width="buttonSize"
                        class="bottom-bar__circle-btn rounded-circle mx-1 elevation-2 btn-style"
                        fab
                        @click="handleClickAddContent"
                        large
                        :ripple="false">
                        <v-icon large>mdi-plus</v-icon>
                    </v-btn>

                    <v-btn :to="{name: 'search'}" :min-width="buttonSize" active-class="orange-color" :ripple="false">
                        <app-icon icon="magnify" :color="getIconColor()" :active="isActive('search')"/>
                    </v-btn>

                    <v-btn :to="{name: 'user.own.profile.view'}" active-class="orange-color"
                           :min-width="buttonSize" :ripple="false">
                        <v-badge
                            v-if="isActiveMessages"
                            :content="unreadMessagesStr"
                            color="#FE4811"
                            :offset-y="10"
                        >
                            <app-icon icon="account"
                                      :color="getIconColor()"
                                      :active="isActive('user.own.profile.view')"
                            />
                        </v-badge>
                        <app-icon v-else
                                  icon="account"
                                  :color="getIconColor()"
                                  :active="isActive('user.own.profile.view')"
                        />
                    </v-btn>
                </div>
            </template>
        </v-bottom-navigation>
        <verify-dialog
            v-if="this.isShowVerifyDialog"
            @close="handleCloseVerifyDialog"
        />

        <verify-content-dialog
            v-if="this.isShowVeriyContentDialog"
            @close="handleCloseVerifyContentDialog"
        />
    </div>
</template>

<script>
import breaks from "@/mixins/breaks";
import RecordScreen from "@/views/app/postingView/RecordScreen";
// import DefaultCameraApp from "@/views/app/postingView/DefaultCameraApp";
import ViewResult from "@/views/app/postingView/ViewResultScreen";
import BeforePostingScreen from "@/views/app/postingView/BeforePostingScreen";
import VerifyDialog from "@/components/app/dialogs/VerifyDialog";
import VerifyContentDialog from "@/components/app/dialogs/VerifyContentDialog";
import {mapMutations, mapState} from 'vuex'

export default {
    name: "BottomBar",
    mixins: [breaks],
    components: {
        RecordScreen,
        // DefaultCameraApp,
        ViewResult,
        BeforePostingScreen,
        VerifyDialog,
        VerifyContentDialog,
    },
    props: {
        model: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            value: 0,
            postingStep: this.$store.state.story.isShowCameraScreen.step,
            isFrontCamera: false,
            isShowVeriyContentDialog:false,
        }
    },
    computed: {
        ...mapState({
            unreadMessages: state => state.user.user.data.unread_messages,
            isShowVerifyDialog: state => state.user.components.isShowVerifyDialog,
            step: state => state.story.isShowCameraScreen.step,
            user: state => state.user.user.data,
        }),
        unreadMessagesStr() {
            return this.unreadMessages > 9 ? '9+' : `${this.unreadMessages}`
        },
        isActiveMessages() {
            return this.unreadMessages > 0
        },
        buttonSize() {
            return this.seOnly ? '58px' : '64px';
        },
        bottomBarHeight() {
            return this.seOnly ? '62px' : '70px';
        },
        unreadNotificationsStr() {
          return this.unreadNotifications > 9 ? '9+' : `${this.unreadNotifications}`
        },
        unreadNotifications() {
            return this.user.unread_notifications;
        },
        isMobile() {
            return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('Mobile') !== -1);
        }
    },
    methods: {
        ...mapMutations([
            'setStep',
            'hideVerifyDialog',
        ]),
        handleCloseVerifyDialog() {
            this.hideVerifyDialog()
        },
        isActive(name) {
            return this.$router.currentRoute.name === name
        },
        getIconColor(name) {
            if (name === 'notification' && this.unreadNotifications > 0) {
                return '#FE4811'
            }

            return '#626262'
        },
        // openDefaultCameraApp() {
        //     const cameraInput = document.getElementById('cameraFileInput');
        //     cameraInput.click();
        // },
        async handleClickAddContent() {
            
            if(this.user.verified == false){
                this.isShowVeriyContentDialog = true;
            } else{
                this.setStep(0)
            }
            // if (this.isMobile) {
            //     this.openDefaultCameraApp()
            // }
        },
        handleCloseResult() {
            this.setStep(0)
            // const newStoryCoreInstance = new StoryCore();
        },
        
        handleCloseVerifyContentDialog(){
            this.isShowVeriyContentDialog = false;
            this.setStep(3)
        }
    },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
@import '../../../sass/modules/variables';

.bottom-bar {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    &--buttons {
        max-width: #{map-get($grid-breakpoints, 'md')};
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__circle-btn {
        background: $accent-background;
        border: 3px solid $bottom-bar-background !important;
        box-sizing: content-box;
    }
}

.bottom-bar .bottom-bar__circle-btn { margin-top: -28px; }
.bottom-bar .v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before, .bottom-bar .v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
    opacity: 0;
}

.orange-color {
    color: #ff7700 !important;
    fill: #ff7700 !important
}

// Border stroke size for low width devices
@media only screen and (max-width: 370px) {
    .bottom-bar__circle-btn.btn-style .v-btn__content::before, .bottom-bar__circle-btn.btn-style .v-btn__content::after {
        top: 61%;
    }
}

</style>
