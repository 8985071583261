<template>
    <app-transition>
        <Dialog
            class="VerifyMessage"
            :only-text="true"
            @close="handleClose"
        >
            <v-row text-center="">
                <v-col>
                    <div class="VerifyMessage-title">
                        {{ $t('dialogs.your_must_before_content') }}
                        <router-link :to="{name: nextStepVerificationRouteName}">
                            {{ $t('dialogs.verify_account') }}
                        </router-link>
                    </div>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>

<script>
import {mapGetters} from "vuex";
import Dialog from "@/components/app/form/Dialog";

export default {
    name: "VerifyContentDialog",
    computed: {
        ...mapGetters([
            'nextStepVerificationRouteName'
        ])
    },
    methods: {
        handleClose() {
            this.$emit('close');
        },
    },
    components: {
        Dialog,
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.VerifyMessage {
    &-title {
        font-size: 20px;
        font-weight: bold;
        line-height: 19px;
        width: 100%;
        a {
            text-decoration: underline;
        }
    }
}
</style>
