<template>
    <app-transition>
        <Dialog @close="$emit('close');">
            <v-row>
                <v-col class="ConfirmEndingStream-message text-center">
                    <p>{{ $t('dialogs.no_streams_scheduled', {username: username}) }}</p>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>

<script>
import Dialog from "@/components/app/form/Dialog"

export default {
    name: "NoStreamsScheduledDialog",
    props: {
        username: {type: String, default: 'User'}
    },
    components: {
        Dialog,
    }
}
</script>
<style scoped lang="scss">
.ConfirmEndingStream {
    &-message {
        font-weight: bold;
        font-size: 20px;
    }
}

.v-btn {
    text-transform: capitalize;
    font-size: 1em !important;
    padding: 10px 28px !important;
}
</style>
